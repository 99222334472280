import React from "react";
import { useSnapshot } from "valtio";
import { authState } from "@shared/states/authState";
import { Link } from "react-router-dom";
import classNames from "classnames";

export interface UpgradeButtonProps {
	enabled: boolean;
	cycle: 1 | 12;
}

const UpgradeButton: React.FC<UpgradeButtonProps> = (props) => {
	const authSnap = useSnapshot(authState);

	const getUpgradeUrl = () => {
		if (!props.enabled) {
			return "#";
		}
		const productId = "lolplanner";
		const searchParams = new URLSearchParams();
		searchParams.set("email", authSnap.user?.email || "");
		searchParams.set("userId", authSnap.user?.id || "");
		searchParams.set("wanted", "true");
		searchParams.set("variant", "Challenger");
		searchParams.set("price", "0");
		if (props.cycle === 12) {
			searchParams.set("yearly", "true");
		}
		const url = `https://duiker101.gumroad.com/l/${productId}?${searchParams.toString()}`;

		return url;
	};

	if (!authSnap.user) {
		return (
			<Link
				className={
					"text-center w-100 rounded p-2 cursor-pointer bg-opacity-80 bg-blend-lighten hover:bg-blend-darken hover:bg-opacity-100 active:bg-slate-700 select-none text-white disabled:opacity-30 bg-blue-500"
				}
				to={"/register"}
			>
				Upgrade Membership
			</Link>
		);
	}

	return (
		<a
			href={getUpgradeUrl()}
			target={props.enabled ? "_blank" : "_self"}
			rel={"noreferrer noopener"}
			className={classNames(
				"text-center w-10/12 rounded p-2 bg-opacity-80 bg-blend-lighten select-none text-white",
				{
					"cursor-pointer bg-blue-500 hover:bg-blend-darken hover:bg-opacity-100 active:bg-slate-700":
						props.enabled,
					"bg-slate-500 pointer-events-none": !props.enabled,
				},
			)}
		>
			{props.enabled ? `Upgrade Membership` : `Membership Active`}
		</a>
	);
};

export { UpgradeButton };
