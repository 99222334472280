import { proxy, subscribe, useSnapshot } from "valtio";
import { MembershipTier, User } from "../api/user";

interface AuthState {
	accessToken?: string;
	user?: User;
}

const saved = JSON.parse(localStorage.getItem("auth") || "{}") || {};

export const authState = proxy<AuthState>(saved);

subscribe(authState, () => {
	localStorage.setItem("auth", JSON.stringify(authState));
});

export const useAuth = () => {
	const authSnap = useSnapshot(authState);

	const isAuthenticated = !!authSnap?.accessToken;

	return { isAuthenticated, user: authSnap.user };
};

export const useHasTier = (tier: MembershipTier) => {
	const { user } = useAuth();
	if (!user?.membershipTier) {
		return false;
	}
	if (user?.membershipTier === "admin") {
		return true;
	}
	if (user?.membershipTier === "Challenger" || user?.membershipTier === "Diamond") {
		return true;
	}
	// if (user?.membershipTier === "Diamond") {
	// 	return tier === "Diamond";
	// }
	console.error(`Unknown tier used ${user?.membershipTier}`);
	return false;
};

// export const PaidFeature = "no-ads"|
// export const useHasFeature = 