import {
	Champion,
	Item,
	MatchSummary,
	MatchTimeline,
	RegionV4,
	RegionV5,
	RuneTree,
	SpectatorGame,
	Summoner,
	SummonerSpell,
} from "../types/riot";
import { LRUCache } from "lru-cache";

const BASE_URL = import.meta.env.VITE_RIOT_API_URL;

const options = { max: 100 };
const summonerCache = new LRUCache<string, string>(options);

interface FetchGameTimelineResponse {
	timeline: MatchTimeline;
}

export async function fetchTimeline(
	region_v5: RegionV5,
	gameId: string,
): Promise<FetchGameTimelineResponse> {
	const res = await fetch(`${BASE_URL}/timeline/${region_v5}/${gameId}`);
	return await res.json();
}

interface FetchGameSummaryResponse {
	summary: MatchSummary;
	error?: APIError;
}

export async function fetchGameSummary(
	region_v5: RegionV5,
	gameId: string,
): Promise<FetchGameSummaryResponse> {
	const cacheKey = region_v5 + gameId;
	const cached = summonerCache.get(cacheKey);
	if (cached) return JSON.parse(cached);
	const res = await fetch(`${BASE_URL}/summary/${region_v5}/${gameId}`);
	const json = await res.json();
	summonerCache.set(cacheKey, JSON.stringify(json));
	return json;
}

export interface APIError {
	message: string;
	code: number;
}

interface FetchSummonerResponse {
	summoner?: Summoner;
	error?: APIError;
}

export async function fetchSummoner(
	region: RegionV5,
	username: string,
): Promise<FetchSummonerResponse> {
	const [name, tag] = username.split("#");
	const res = await fetch(`${BASE_URL}/summoner/${region}/${name}/${tag}`);
	return await res.json();
}

interface FetchHistoryResponse {
	history?: string[];
	summoner?: Summoner;
	error?: APIError;
}

export async function fetchHistory(
	region: RegionV5,
	puuid: string,
	queueId: string,
): Promise<FetchHistoryResponse> {
	const res = await fetch(`${BASE_URL}/history/${region}/puuid/${puuid}/${queueId}`);
	return await res.json();
}

interface FetchStaticsResponse {
	champions: Record<string, Champion>;
	summoners: Record<string, SummonerSpell>;
	items: Record<string, Item>;
	runes: Array<RuneTree>;
	version: string;
}

export async function fetchStatics(): Promise<FetchStaticsResponse> {
	const res = await fetch(`${BASE_URL}/dragon`);
	return await res.json();
}

interface FetchSpectatorResponse {
	spectator?: SpectatorGame;
	error?: APIError;
}

export async function fetchSpectator(
	region: RegionV4,
	summonerId: string,
): Promise<FetchSpectatorResponse> {
	const res = await fetch(`${BASE_URL}/spectator/${region}/${summonerId}`);
	return await res.json();
}

export interface FeaturedGameParticipant {
	riotId: string;
	puuid: string;
}

interface FetchFeaturedGamesResponse {
	spectator?: {
		gameList: {
			gameQueueConfigId: number;
			participants: FeaturedGameParticipant[];
		}[];
	};
}

export async function fetchFeaturedGames(region: RegionV4): Promise<FetchFeaturedGamesResponse> {
	const res = await fetch(`${BASE_URL}/featured/${region}`);
	return await res.json();
}

export async function fetchLatestGameVersion(): Promise<{ version: string }> {
	return await fetch(`${BASE_URL}/version`).then((res) => res.json());
}
